/* eslint-disable @next/next/no-head-element */

'use client'

import { usePathname, useRouter } from 'next/navigation';
import Script from 'next/script';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import Main from './main';
import {ErrorBoundary} from 'react-error-boundary';

const ErrorFallback = ({error, resetErrorBoundary}: { error: Error, resetErrorBoundary: any}) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const myErrorHandler = (error: Error) => {
  // Do something with the error
  // E.g. log to an error logging client here
  console.error("DEBUG ERROR", error)
}

const RootLayout = ({
  // Layouts must accept a children prop.
  // This will be populated with nested layouts or pages
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const path = usePathname();
  
  useEffect(() => {
    if(process.env.NEXT_PUBLIC_SPLASH) {
      router.replace('/rest-splash')
    }

    // Helper function
    const domReady = (cb: () => void) => {
      document.readyState === 'interactive' || document.readyState === 'complete'
        ? cb()
        : document.addEventListener('DOMContentLoaded', cb);
    };

    domReady(() => {
      // Display body when DOM is loaded
      document.body.style.visibility = 'visible';
    });

    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID as string });

    try {
      function myCallback() {
        console.log("Identify has been completed");
      }
      
      window.klaviyo.identify({}, myCallback);
    } catch (e) {

    }

  }, [])
  

  useEffect(() => {     
    const handleRouteChange = (url: string) => {
      if (typeof window.gtag !== 'undefined') {
        window.gtag('config', process.env.NEXT_PUBLIC_GA_ID, {
          page_path: url,
        })
      }
    }

    if(process.env.NODE_ENV === "production") {
      handleRouteChange(window.location.href);
    }

  }, [path]);
  
  return (
    <html lang="en">
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Urbanist:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff"></meta>
        <link rel="icon" href="/favicon.ico" />
        <script dangerouslySetInnerHTML={{ __html: `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${process.env.NEXT_PUBLIC_FB_PIXEL_ID}');
          fbq('track', 'PageView');` }}
        />
        <noscript dangerouslySetInnerHTML={{ __html: `<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FB_PIXEL_ID}&ev=PageView&noscript=1" />` }}
        />
      </head>
      <body style={{ visibility: "hidden" }}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
          <>
            <Script async src="https://kit.fontawesome.com/ceecfad9a8.js" crossOrigin="anonymous"></Script>
            <Script async type="text/javascript" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Yrca4a" />
            { process.env.NODE_ENV === "production" ? (
              <>
                <Script
                  strategy="lazyOnload"
                  src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
                />
                <Script id="google-analytics" strategy="lazyOnload">
                  {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
                      page_path: window.location.pathname,
                    });
                  `}
                </Script>

              </>
            ) : null}
            <Main>{children}</Main>
          </>
          <div id="modal-root"></div>
          <noscript><style jsx>{`body { visibility: visible; }`}</style></noscript>
        </ErrorBoundary>
      </body>
    </html>
  );
}

export default RootLayout;


