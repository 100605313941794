import { motion, AnimatePresence } from "framer-motion";
import { usePathname } from "next/navigation";
import { ReactNode } from "react";
import styled from "styled-components";

type PageTransitionProps = {
  children: ReactNode;
}

const LogoLoading = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: -1;

  svg { 
    width: 4rem;
    height: auto;
  }
`

const Main = styled(motion.div)`
  background: white;
`

const PageTransition = ({ children }: PageTransitionProps) => {
  const path = usePathname();

  return (
    <Main>
      { children }
    </Main>
  )

  return (
    <AnimatePresence mode="wait">
      <Main
        key={path}
        initial={{ opacity: 0, scale: 0.99, originY: "1rem" }}
        animate={{
          opacity: 1,
          scale: 1,
          transitionEnd: {
            originY: 0
          },
        }}
        exit={{ opacity: 0, scale: 0.99, originY: "1rem" }}
        transition={{ duration: 0.5, ease: [0.77, 0, 0.175, 1]}}
      >
        { children }
      </Main>
      {/* <LogoLoading
        initial={{ opacity: 1 }}
        animate={{
          opacity: 0,
        }}
        exit={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: [0.77, 0, 0.175, 1]}}
      >
        <svg className="logo-loading" width="343" height="193" viewBox="0 0 343 193" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M323.841 14.208L127.431 0.0679769C123.45 -0.22176 119.456 0.417338 115.764 1.93489C112.072 3.45244 108.783 5.80696 106.156 8.81276C103.529 11.8186 101.637 15.3934 100.628 19.2556C99.6188 23.1177 99.5207 27.1615 100.341 31.068L129.051 168.398C129.213 169.173 129.133 169.98 128.823 170.708C128.513 171.437 127.988 172.054 127.317 172.475C126.646 172.896 125.863 173.102 125.072 173.065C124.281 173.028 123.52 172.75 122.891 172.268L73.6714 134.458L22.1714 94.898C21.2388 94.1722 20.5308 93.1972 20.1291 92.0859C19.7274 90.9746 19.6484 89.7723 19.9013 88.618C20.1859 87.3544 20.8604 86.2121 21.8293 85.3526C22.7983 84.4931 24.0129 83.9598 25.3014 83.828L73.6714 78.6879L93.6114 76.5679L89.5514 57.138L73.6714 58.828L23.2113 64.188C18.0541 64.7335 13.1787 66.8123 9.21464 70.1561C5.25056 73.4999 2.37975 77.9552 0.972821 82.9467C-0.434111 87.9383 -0.312555 93.2369 1.32176 98.1587C2.95607 103.08 6.02812 107.399 10.1413 110.558L73.6714 159.358L110.871 187.928C114.862 191.01 119.737 192.729 124.779 192.83C129.82 192.93 134.761 191.408 138.871 188.488L333.971 50.748C337.364 48.3527 339.933 44.9673 341.326 41.0554C342.72 37.1435 342.871 32.8964 341.757 28.8957C340.644 24.895 338.321 21.3364 335.106 18.707C331.892 16.0777 327.943 14.5063 323.801 14.208H323.841Z" fill="black"/>
        </svg>
      </LogoLoading> */}
    </AnimatePresence>
  )
}

export default PageTransition;