import { css } from 'styled-components';

const Markdown = css`
  .prose {
    word-wrap: break-word;
    font-size: 1rem;

    @media ${({ theme }) => theme.mediaQuery.phone} {
      font-size: 1.1rem;
    }

    .intro {
      margin-bottom: 3rem;
    }

    .content > .inner > * {
      max-width: 45rem;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 0 1rem;
    }
    
    .starter {
      /* font-size: 1.1rem; */
      font-weight: 500;
    }

    hr {
      margin: 3rem;
      overflow: visible;
      border: none;
      text-align: center;

      &:after {
        content: "* * *";
      }
    }

    p, li {
      line-height: 1.7;
      font-weight: 400;
      font-size: 1.1rem;
    }

    h1 {
      letter-spacing: -0.1rem;
      font-family: ${({ theme }) => theme.fonts.secondary };
      font-weight: 600;
      line-height: 1.2;

      @media ${({ theme }) => theme.mediaQuery.tablet} {
        font-size: 3.6rem;
      }
    }

    h2 {
      font-weight: 300;
      font-family: ${({ theme }) => theme.fonts.primary};
      font-style: italic;
      letter-spacing: -0.07rem;
      font-size: 1.6rem;
      color: #999999;
      line-height: 1.4;

      @media ${({ theme }) => theme.mediaQuery.phone} {
        font-size: 1.8rem;
        letter-spacing: -0.1rem;
      }
    }

    h3 {
      font-family: ${({ theme }) => theme.fonts.primary};
      font-weight: 500;
      letter-spacing: -0.05rem;
      font-size: 1.3rem;
      margin-bottom: 1rem;
      margin-top: 3rem;

      @media ${({ theme }) => theme.mediaQuery.phone} {
        letter-spacing: -0.1rem;
        font-size: 1.5rem;
      }
    }

    h4 {
      font-family: ${({ theme }) => theme.fonts.primary};
      font-weight: bold;
      letter-spacing: -0.03rem;
      font-size: 1.1rem;
      margin-bottom: 1rem;
      margin-top: 3rem;

      @media ${({ theme }) => theme.mediaQuery.phone} {
        letter-spacing: -0.07rem;
        font-size: 1.1rem;
      }
    }

    p {
      margin-bottom: 2rem;
    }

    blockquote {
      font-size: 1.3rem;
      font-style: italic;
      font-weight: 300;
      font-family: ${({ theme }) => theme.fonts.secondary};
      color: #999999;
      max-width: 35rem;
      width: 100%;
      margin: 4rem auto;
      padding: 0 2rem !important;

      p {
        margin-bottom: 0;
      }
    }

    ol, ul {
      margin: 1.5rem 1rem 2rem;

      li {
        margin: 0.5rem 0 0.5rem 2rem;
      }

      ol, ul {
        margin: 0.5rem 1rem 0.5rem 0rem;
      }

      img {
        padding-top: 1rem;
      }
    }

    .img-oversize {
      max-width: 50rem !important;
    }

    .img-hero {
      max-width: none !important;
      padding: 0 !important;
      
      &.img-height-small {
        img {
          height: clamp(30rem, 60vh, 50rem);
        }
      }

      &.img-height-smallTablet {
        img {
          height: clamp(30rem, 70vh, 50rem);
        }
      }

      &.img-height-tablet {
        img {
          height: clamp(30rem, 80vh, 50rem);
        }
      }
    }

    img, .link-img {
      width: 100%;
      object-fit: cover;
      display: block;
      margin: 1rem 0;

      + em {
        font-size: 0.7rem;
        margin: -0.5rem 1rem 1rem;
        display: block;
      }
    }

    a {
      color: black;

      &:hover {
        text-decoration: underline;
      }
    }

    .caption {
      font-size: 0.7rem;
      margin: -0.5rem 1rem 1rem;
      display: block;
    }

    code {
      display: block;
      background: #f0f0f0;
      padding: 0.5rem;
      margin: 1rem;
      color: #666;
      font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
    }
  }
`

export default Markdown;